import { History } from 'history';
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { AccountManagmentTable } from "../../network/models/TableInterfaces";
import { createClickableCell, createInfoCell } from "../../utilities/CreateCell";
import { createRow } from "../../utilities/CreateRow";
import { UserRole } from "../../utilities/Enums";
import { isWholesalerUser, isRoleIncluded } from "../../utilities/checkUserRole";


export const createAccountManagmentRows = (
  data: Array<AccountManagmentTable>,
  history: History,
  userRole: string,
  postMethod: any,
) => {
  const rows: Array<TableRowProps> = [];
  data.forEach((entry) => {
    const row: TableRowProps = createRow(
      createDoingBusinessAsCell(entry, userRole, history),
      createReturnsCell(entry, userRole, history),
      createStartNewReturnCell(entry, userRole, history, postMethod,  entry.enabled)
    );
    rows.push(row);
  });
  return rows;
};

const createDoingBusinessAsCell = (entry: any, userRole: string, history: History) => {
    return createClickableCell(() => {
      switch (userRole) {
        case UserRole.Admin:
        case UserRole.FieldProcessor:
          history.push(`/admin/pharmacies/${entry.pharmacyId}`);
          break;
        case UserRole.WholesalerUser:
          history.push(`/wholesaler-user/pharmacies/${entry.pharmacyId}`);
          break;
        default:
          history.push(`/pharmacies/${entry.pharmacyId}`);
      }
    }, entry.doingBusinessAs);
}

const createReturnsCell = (entry: any, userRole: string, history: History) => {
  const state = entry.pharmacyId;
  return createClickableCell(() => {
    switch (userRole) {
      case UserRole.Admin:
        history.push({ pathname: `/admin/pharmacies/${entry.pharmacyId}/returns`, state });
        break;
      case UserRole.WholesalerUser:
        history.push({ pathname: `/wholesaler-user/pharmacies/${entry.pharmacyId}/returns`, state })
        break;
      default:
        history.push({ pathname: `/pharmacies/${entry.pharmacyId}/returns`, state });
    }
  }, entry.numberOfReturns.toString(10))
}

const createStartNewReturnCell = (entry: any, userRole: string, history: History, postMethod: any, status: boolean) => {
  const cellContent = status
    ? "Start New Return"
    : "Pharmacy Disabled";
  if (!isWholesalerUser(userRole) && status) {
    return createClickableCell(() => {
      if (isRoleIncluded([UserRole.Admin, UserRole.Warehouse, UserRole.FieldProcessor], userRole)) {
        history.push(
          `/admin/pharmacies/${entry.pharmacyId}/returnrequests/add`
        );
      } else {
        history.push(`/pharmacies/${entry.pharmacyId}/returnrequest/create`);
      }
    }, cellContent);
  }else {
    return createInfoCell(cellContent);
  }
}
